import { Close } from '@mui/icons-material'
import {
  Dialog,
  Tabs,
  Tab,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  useTheme,
  styled,
  SxProps,
} from '@mui/material'
import React, { useState, useContext } from 'react'
import { useTranslate } from 'react-admin'

import {
  ImportUploadFile,
  ImportMatchColumns,
  ImportFinalization,
  ImportFlushData,
} from 'libs/import/components'
import { ImportErrors } from 'libs/import/components/ImportErrors'
import { ImportUsersContext, ValidationSteps } from 'libs/import/context'
import { useModal } from 'libs/modal'
import { UserCreate } from 'resources/user/UserCreate'
import { Spacer } from 'utils/spacing'

export const AddOrImportUserModal = () => {
  const t = useTranslate()
  const theme = useTheme()
  const [value, setValue] = useState('create')
  const { closeModal } = useModal('addOrImportUsers')
  const { currentStep, validationStep, setValidationStep } = useContext(ImportUsersContext)

  const handleTabChange = (event: React.SyntheticEvent, newValue: any) => {
    const target = event.target as HTMLElement
    setValue(newValue)
    target.blur()
  }

  const UserImport = () => {
    if (validationStep === ValidationSteps.FLUSH_DATA) return <ImportFlushData />
    if (currentStep === 0) return <ImportUploadFile />
    if (currentStep === 1) {
      if (validationStep === ValidationSteps.COLUMNS_MATCHING) return <ImportMatchColumns />
    }
    if (currentStep === 1.5) return <ImportErrors />
    if (currentStep === 2) return <ImportFinalization />
    return <></>
  }

  function handleClose() {
    setValidationStep(ValidationSteps.FLUSH_DATA)
    closeModal()
  }

  return (
    <Dialog
      scroll="paper"
      open
      fullWidth
      maxWidth={validationStep === ValidationSteps.TABLE_EDIT ? 'xl' : 'lg'}
      onClose={handleClose}>
      <DialogTitle sx={{ display: 'flex', height: '50px', padding: 0 }}>
        <Tabs
          sx={{ width: '100%', height: '100%' }}
          value={value}
          onChange={handleTabChange}
          variant="fullWidth">
          <Tab label={t('resources.contract.addImportTabs.create')} value="create" sx={tabSx} />
          <Tab label={t('resources.contract.addImportTabs.import')} value="import" sx={tabSx} />
        </Tabs>
        <DialogActions
          sx={{
            height: '100%',
            width: '10%',
            backgroundColor: theme.palette.secondary.main,
            padding: 0,
          }}>
          <StyledIconButton
            sx={{ height: '100%', width: '100%' }}
            size="medium"
            onClick={handleClose}>
            <Close />
          </StyledIconButton>
        </DialogActions>
      </DialogTitle>
      <DialogContent sx={{ width: '100%', height: '100vh', padding: 0 }}>
        <Spacer y={2} />
        {value === 'create' && <UserCreate onSuccess={closeModal} />}
        {value === 'import' && <UserImport />}
      </DialogContent>
    </Dialog>
  )
}

const tabSx: SxProps = { height: '100%' }

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&:hover': { borderColor: theme.palette.secondary.main },
}))
