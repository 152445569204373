import {
  CloseRounded,
  DownloadDoneRounded,
  SimCardDownloadRounded,
  InfoRounded,
} from '@mui/icons-material'
import {
  styled,
  useTheme,
  SxProps,
  Button,
  SvgIcon,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  LinearProgress,
  IconButton,
} from '@mui/material'
import { xor } from 'lodash'
import React, { useContext, useState, useEffect } from 'react'
import { useRefresh, useTranslate } from 'react-admin'
import { useMutation } from 'react-query'

import { useApi } from 'api'
import { Contract } from 'api/gen'
import { HorizontalStepper } from 'libs/import/components/Stepper'
import { ImportUsersContext } from 'libs/import/context'
import { useModal } from 'libs/modal'
import { Column, Spacer, Row } from 'utils/spacing'
import { useEffectOnce } from 'utils/useEffectOnce'

import { SummaryDialog } from './ImportSummaryDialog'

type DialogType = 'create' | 'update' | 'delete'

export const ImportFinalization = () => {
  const t = useTranslate()
  const api = useApi()
  const theme = useTheme()
  const refresh = useRefresh()
  const { closeModal } = useModal('addOrImportUsers')

  const [progress, setProgress] = useState(-1)
  const [progressTimer, setProgressTimer] = useState<NodeJS.Timer | null>(null)

  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const [numberOfUsersToCreate, setNumberOfUsersToCreate] = useState(0)
  const [numberOfUsersToModify, setNumberOfUsersToModify] = useState(0)
  const [numberOfUsersToDelete, setNumberOfUsersToDelete] = useState(0)

  const [usersToCreate, setUsersToCreate] = useState<Contract[]>([])
  const [usersToModify, setUsersToModify] = useState<Contract[]>([])
  const [usersToDelete, setUsersToDelete] = useState<Contract[]>([])

  const [shouldIgnoreCreate, setIgnoreCreate] = useState(false)
  const [shouldIgnoreUpdate, setIgnoreUpdate] = useState(false)
  const [shouldIgnoreDelete, setIgnoreDelete] = useState(true)

  const createOrUpdateMutation = useMutation('importCreateOrUpdate', createOrUpdateUsers)
  const showProgressBar = (progress >= 0 && progress < 100) || createOrUpdateMutation.isLoading

  const {
    currentStep,
    fileContent,
    importedFields,
    usersToDelete: usersToDeleteFromFile,
    setCurrentStep,
    flushContext,
  } = useContext(ImportUsersContext)

  useEffect(() => {
    if (!showProgressBar) {
      refresh()
      if (progressTimer) clearInterval(progressTimer)
    }
  }, [showProgressBar, progressTimer, refresh])

  useEffectOnce(() => {
    initUserSummary()
  })

  async function initUserSummary() {
    const staffNumbers: string[] = []
    const fieldName = (importedFields as any).staffNumber
    fileContent.forEach((row: any) => staffNumbers.push(row[fieldName]))
    const response = await api.importControllerImportSummary({
      importedUsers: fileContent,
    })
    setNumberOfUsersToCreate(response.nbToCreate)
    setNumberOfUsersToModify(response.nbToUpdate)
    setNumberOfUsersToDelete(response.nbToDelete)
    setUsersToCreate(response.importedContractsToCreate)
    setUsersToModify(response.importedContractsToUpdate)
    setUsersToDelete(response.contractsToDelete)
  }

  async function createOrUpdateUsers() {
    const importedUsers = xor(fileContent, usersToDeleteFromFile)
    setProgress(0)
    const timer = setInterval(() => {
      setProgress((oldProgress) => Math.min(oldProgress + 2, 100))
    }, 1000)
    setProgressTimer(timer)
    await api.importControllerImportCreate({
      shouldCreate: shouldIgnoreCreate ? false : numberOfUsersToCreate > 0,
      shouldUpdate: shouldIgnoreUpdate ? false : numberOfUsersToModify > 0,
      shouldDelete: shouldIgnoreDelete ? false : numberOfUsersToDelete > 0,
      importedUsers: importedUsers,
    })
  }

  function getCreateHelperText() {
    if (createOrUpdateMutation.isError || createOrUpdateMutation.isSuccess) {
      return t('resources.contract.helperTexts.importTab.createFinalSummary', numberOfUsersToCreate)
    }
    return t('resources.contract.helperTexts.importTab.createSummary', numberOfUsersToCreate)
  }

  function getDeleteHelperText() {
    if (createOrUpdateMutation.isError || createOrUpdateMutation.isSuccess) {
      return t('resources.contract.helperTexts.importTab.deleteSummaryFinal', numberOfUsersToDelete)
    }
    return t('resources.contract.helperTexts.importTab.deleteSummary', numberOfUsersToDelete)
  }

  function getUpdateHelperText() {
    if (createOrUpdateMutation.isError || createOrUpdateMutation.isSuccess) {
      return t('resources.contract.helperTexts.importTab.modifySummaryFinal', numberOfUsersToModify)
    }
    return t('resources.contract.helperTexts.importTab.modifySummary', numberOfUsersToModify)
  }

  function getIconColor() {
    if (createOrUpdateMutation.isError) return theme.colors.RED
    if (createOrUpdateMutation.isSuccess) return theme.colors.GREEN
    return theme.palette.secondary.main
  }

  function getHelperTextTitle() {
    if (createOrUpdateMutation.isError)
      return t('resources.contract.helperTexts.importTab.importHasFailed')
    if (createOrUpdateMutation.isSuccess)
      return t('resources.contract.helperTexts.importTab.importHasSucceded')
    return t('resources.contract.helperTexts.importTab.importIsReady')
  }

  function handleClose() {
    flushContext()
    closeModal()
  }

  function handleOpenDialog(event: React.SyntheticEvent, type: DialogType) {
    const target = event.target as HTMLElement
    if (type === 'create') setOpenCreateDialog(true)
    if (type === 'update') setOpenUpdateDialog(true)
    if (type === 'delete') setOpenDeleteDialog(true)
    target.blur()
  }

  function getDialogTitle(type: DialogType) {
    if (type === 'create') {
      if (createOrUpdateMutation.isSuccess) {
        return t(
          'resources.contract.helperTexts.importTab.createDialogSuccess',
          numberOfUsersToCreate
        )
      }
      return (
        numberOfUsersToCreate +
        ' ' +
        t('resources.contract.helperTexts.importTab.createDialog', numberOfUsersToCreate)
      )
    }
    if (type === 'update') {
      if (createOrUpdateMutation.isSuccess) {
        return t(
          'resources.contract.helperTexts.importTab.updateDialogSuccess',
          numberOfUsersToModify
        )
      }
      return (
        numberOfUsersToModify +
        ' ' +
        t('resources.contract.helperTexts.importTab.updateDialog', numberOfUsersToModify)
      )
    }
    if (type === 'delete') {
      if (createOrUpdateMutation.isSuccess) {
        return t(
          'resources.contract.helperTexts.importTab.deleteDialogSuccess',
          numberOfUsersToDelete
        )
      }
      return (
        numberOfUsersToDelete +
        ' ' +
        t('resources.contract.helperTexts.importTab.deleteDialog', numberOfUsersToDelete)
      )
    }
    return ''
  }

  const Summary = () => {
    return (
      <>
        <Column sx={{ textAlign: 'center', width: '120px' }}>
          <StyledIconButton
            onClick={(event) => handleOpenDialog(event, 'create')}
            disabled={!numberOfUsersToCreate}
            sx={{ color: theme.colors.GREY }}>
            <InfoRounded
              sx={{ ...infoIconSx, visibility: !numberOfUsersToCreate ? 'hidden' : 'visible' }}
            />
          </StyledIconButton>
          <Typography color="primary" sx={{ fontWeight: 500, fontSize: '40px' }}>
            {!createOrUpdateMutation.isError ? numberOfUsersToCreate : 0}
          </Typography>
          <Typography sx={{ fontSize: '16px' }}>{getCreateHelperText()}</Typography>
          {!createOrUpdateMutation.isError && !createOrUpdateMutation.isSuccess && (
            <FormGroup sx={{ alignSelf: 'center' }}>
              <FormControlLabel
                sx={{ color: theme.colors.GREY }}
                control={
                  <Checkbox
                    sx={{ color: theme.colors.GREY }}
                    onChange={(event) => setIgnoreCreate(event.target.checked)}
                    checked={shouldIgnoreCreate}
                  />
                }
                label={t('resources.contract.helperTexts.importTab.ignoreField')}
              />
            </FormGroup>
          )}
          <SummaryDialog
            open={openCreateDialog}
            handleClose={() => setOpenCreateDialog(false)}
            type="create"
            title={getDialogTitle('create')}
            contracts={usersToCreate}
          />
        </Column>
        <Spacer x={9} />
        <Column sx={{ textAlign: 'center', width: '120px' }}>
          <StyledIconButton
            onClick={(event) => handleOpenDialog(event, 'update')}
            disabled={!numberOfUsersToModify}
            sx={{ color: theme.colors.GREY }}>
            <InfoRounded
              sx={{ ...infoIconSx, visibility: !numberOfUsersToModify ? 'hidden' : 'visible' }}
            />
          </StyledIconButton>
          <Typography color="primary" sx={{ fontWeight: 500, fontSize: '40px' }}>
            {!createOrUpdateMutation.isError ? numberOfUsersToModify : 0}
          </Typography>
          <Typography sx={{ fontSize: '16px' }}>{getUpdateHelperText()}</Typography>
          {!createOrUpdateMutation.isError && !createOrUpdateMutation.isSuccess && (
            <FormGroup sx={{ alignSelf: 'center' }}>
              <FormControlLabel
                sx={{ color: theme.colors.GREY }}
                control={
                  <Checkbox
                    sx={{ color: theme.colors.GREY }}
                    onChange={(event) => setIgnoreUpdate(event.target.checked)}
                    checked={shouldIgnoreUpdate}
                  />
                }
                label={t('resources.contract.helperTexts.importTab.ignoreField')}
              />
            </FormGroup>
          )}
          <SummaryDialog
            open={openUpdateDialog}
            handleClose={() => setOpenUpdateDialog(false)}
            type="update"
            title={getDialogTitle('update')}
            contracts={usersToModify}
          />
        </Column>
        <Spacer x={9} />
        <Column sx={{ textAlign: 'center', width: '120px' }}>
          <StyledIconButton
            onClick={(event) => handleOpenDialog(event, 'delete')}
            disabled={!numberOfUsersToDelete}
            sx={{ color: theme.colors.GREY }}>
            <InfoRounded
              sx={{ ...infoIconSx, visibility: !numberOfUsersToDelete ? 'hidden' : 'visible' }}
            />
          </StyledIconButton>
          <Typography color="primary" sx={{ fontWeight: 500, fontSize: '40px' }}>
            {!createOrUpdateMutation.isError ? numberOfUsersToDelete : 0}
          </Typography>
          <Typography sx={{ fontSize: '16px' }}>{getDeleteHelperText()}</Typography>
          {!createOrUpdateMutation.isError && !createOrUpdateMutation.isSuccess && (
            <FormGroup sx={{ alignSelf: 'center' }}>
              <FormControlLabel
                sx={{ color: theme.colors.GREY }}
                control={
                  <Checkbox
                    sx={{ color: theme.colors.GREY }}
                    onChange={(event) => setIgnoreDelete(event.target.checked)}
                    checked={shouldIgnoreDelete}
                  />
                }
                label={t('resources.contract.helperTexts.importTab.ignoreField')}
              />
            </FormGroup>
          )}
          <SummaryDialog
            open={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            title={getDialogTitle('delete')}
            type="delete"
            contracts={usersToDelete}
          />
        </Column>
      </>
    )
  }

  if (showProgressBar) {
    return (
      <Column sx={columnSx}>
        <Spacer y={2} />
        <HorizontalStepper activeStep={currentStep} />
        <Spacer y={5} />
        <StyledSquare>
          <StyledCircle>
            <SvgIcon sx={{ width: '40px', height: '50px' }}>
              <SimCardDownloadRounded />
            </SvgIcon>
          </StyledCircle>
          <Spacer y={3} />
          <StyledTypography variant="h2" sx={{ fontWeight: 500 }}>
            {t('resources.contract.helperTexts.importTab.importInProgress')}
          </StyledTypography>
          <Spacer y={3} />
          <LinearProgress
            color="secondary"
            variant="determinate"
            value={progress}
            sx={{ width: '75%' }}
          />
        </StyledSquare>
      </Column>
    )
  }

  return (
    <Column sx={columnSx}>
      <Spacer y={2} />
      <HorizontalStepper activeStep={currentStep} />
      <Spacer y={5} />
      <StyledSquare>
        <StyledCircle>
          <SvgIcon sx={{ width: '40px', height: '50px', color: getIconColor() }}>
            {!createOrUpdateMutation.isError ? <DownloadDoneRounded /> : <CloseRounded />}
          </SvgIcon>
        </StyledCircle>
        <Spacer y={3} />
        <StyledTypography variant="h2" sx={{ fontWeight: 500 }}>
          {getHelperTextTitle()}
        </StyledTypography>
        <Spacer y={3} />
        <Row>
          <Summary />
        </Row>
      </StyledSquare>
      <Spacer y={5} />
      {!createOrUpdateMutation.isError && !createOrUpdateMutation.isSuccess && (
        <Row>
          <Button sx={buttonSx} onClick={() => createOrUpdateMutation.mutate()}>
            {t('buttons.import.completeImport')}
          </Button>
        </Row>
      )}
      {createOrUpdateMutation.isSuccess && (
        <Row>
          <Button variant="contained" color="primary" sx={buttonSx} onClick={handleClose}>
            {t('buttons.close')}
          </Button>
        </Row>
      )}
      {createOrUpdateMutation.isError && (
        <Row>
          <Button
            variant="contained"
            color="primary"
            sx={buttonSx}
            onClick={() => setCurrentStep(1)}>
            {t('buttons.import.prevStep')}
          </Button>
        </Row>
      )}
    </Column>
  )
}

const infoIconSx: SxProps = {
  height: '20px',
  width: '20px',
}

const buttonSx: SxProps = {
  width: '180px',
  height: '45px',
  fontWeight: 400,
  padding: 0,
}
const columnSx: SxProps = {
  display: 'flex',
  width: '100%',
  height: '90%',
  alignItems: 'center',
}

const StyledSquare = styled(Column)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '650px',
  height: '390px',
  border: '2px solid',
  borderColor: theme.palette.secondary.main,
  borderRadius: '10px',
}))

const StyledCircle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80px',
  height: '80px',
  border: '1px solid',
  borderRadius: '50px',
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  height: '50px',
  width: '400px',
  textAlign: 'center',
  fontSize: '16px',
  color: theme.palette.secondary.main,
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  alignSelf: 'end',
  marginBottom: '-25px',
  ':hover': {
    backgroundColor: theme.palette.text.secondary,
    borderColor: theme.palette.text.secondary,
  },
}))
