import { CancelRounded, CheckCircleRounded } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  styled,
  SvgIcon,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'
import { isEqual } from 'lodash'
import React, { useContext, useState } from 'react'
import { SelectInput, SimpleForm, useTranslate } from 'react-admin'

import { useApi } from 'api'
import { HorizontalStepper } from 'libs/import/components/Stepper'
import {
  AdvanceServiceValues,
  FIELDS,
  NesspayFields,
  REQUIRED_FIELDS,
  USER_FIELDS,
  VALID_COLUMNS_NAMES,
} from 'libs/import/constants'
import { ImportUsersContext, ValidationSteps } from 'libs/import/context'
import { convertFrenchDateToISOString, isValidFrenchDate } from 'libs/validation/date'
import { parseBoolean } from 'utils/format'
import { Column, Row, Spacer } from 'utils/spacing'

const COMMAS_AND_SPACES_REGEX = /[ ,]/g

interface FieldProps {
  name: string
}

const getKeyByValue = (object: any, value: string) => {
  const nesspayKey = Object.keys(object).find((key) => object[key] === value)
  if (!nesspayKey) {
    return value
  }
  return nesspayKey
}

interface Errors {
  [cellId: string]: string
}

export const ImportMatchColumns = () => {
  const api = useApi()
  const t = useTranslate()
  const theme = useTheme()
  const {
    currentStep,
    fileContent,
    missingFields,
    importedFields,
    setFileContent,
    setValidationStep,
    setMissingFields,
    setCurrentStep,
    setErrors,
  } = useContext(ImportUsersContext)
  const [ignoredFields, setIgnoreFields] = useState<string[]>([])

  const columnsNames = Object.keys(fileContent[0])
  const unmatchedColumns: any = columnsNames.filter(
    (col) => !Object.values(importedFields).includes(col)
  )

  function handleSelectionChange(child: any, fieldName: string) {
    let newMissingFields: string[] = [...missingFields]
    const columnName = importedFields[fieldName as unknown as number]
    if (missingFields.includes(child)) {
      newMissingFields = missingFields.filter((field) => field !== child)
      const tmp = importedFields[child]
      importedFields[child] = columnName
      importedFields[fieldName as unknown as number] = tmp
    } else if (!child) newMissingFields.push(fieldName)
    setMissingFields(newMissingFields)
  }

  function formatPhoneNumber(value: string | number) {
    if (typeof value === 'number') {
      const formatedValue = value.toString()
      if (formatedValue.length && formatedValue[0] !== '0') return '0' + formatedValue
      return formatedValue
    }
    if (value.length && value[0] !== '0' && value[0] !== '+') return '0' + value
    return value
  }

  function getFormatedContent() {
    const formatedContent: any[] = []
    fileContent.forEach((row: any) => {
      const newRow: any = {}
      for (const key in row) {
        const nesspayKey = getKeyByValue(importedFields, key)
        if (nesspayKey) {
          if (nesspayKey === NesspayFields.IS_ADVANCE_SERVICE_ENABLED) {
            newRow[nesspayKey] = parseBoolean(row[key])
              ? AdvanceServiceValues.YES
              : AdvanceServiceValues.NO
          } else if (nesspayKey === NesspayFields.STAFF_NUMBER && typeof row[key] === 'number') {
            newRow[nesspayKey] = row[key].toString()
          } else if (nesspayKey === NesspayFields.PHONE) {
            newRow[nesspayKey] = formatPhoneNumber(row[key])
          } else if (nesspayKey === NesspayFields.NET_SALARY && typeof row[key] === 'string') {
            newRow[nesspayKey] = parseFloat(row[key].replace(COMMAS_AND_SPACES_REGEX, ''))
          } else if (
            nesspayKey === NesspayFields.AVAILABLE_SALARY_FRACTION &&
            typeof row[key] === 'string'
          ) {
            newRow[nesspayKey] = parseInt(row[key].replace(COMMAS_AND_SPACES_REGEX, ''))
          } else if (
            nesspayKey === NesspayFields.CONTRACT_START_DATE ||
            nesspayKey === NesspayFields.CONTRACT_END_DATE
          ) {
            if (isValidFrenchDate(row[key]))
              newRow[nesspayKey] = convertFrenchDateToISOString(row[key])
            else newRow[nesspayKey] = row[key]
          } else {
            newRow[nesspayKey] = row[key]
          }
        }
      }
      formatedContent.push(newRow)
    })
    ignoredFields.forEach((field) => {
      formatedContent.forEach((row) => delete row[field])
    })
    return formatedContent
  }

  async function validateUniquenessInDB(fileContent: any): Promise<void> {
    // const joiErrors: Errors = {}
    const joiErrors: Errors = {}
    fileContent.forEach((row: any) => {
      for (const key in row) {
        if (row[key] === null || row[key] === undefined || row[key] === '') delete row[key]
      }
    })
    const response = await api.importControllerImportValidation({ importedUsers: fileContent })
    if (!response.isValid) {
      setErrors(response.errors)
      setCurrentStep(1.5)
    } else {
      setCurrentStep(2)
    }
  }

  async function handleNextStep() {
    const newContent = getFormatedContent()
    setFileContent(newContent)
    await validateUniquenessInDB(newContent)
  }

  function handleIgnoreField(event: React.ChangeEvent<HTMLInputElement>, fieldName: string) {
    let fields: string[] = []
    if (event.target.checked) {
      fields = [...ignoredFields]
      fields.push(fieldName)
      setIgnoreFields(fields)
    } else {
      fields = ignoredFields.filter((value) => value !== fieldName)
      setIgnoreFields(fields)
    }
  }

  function getFieldsSelection(missingFields: string[], field: string) {
    const fieldName = USER_FIELDS.includes(field) ? 'user.' + field : field
    const availableFields = [...missingFields]

    Object.keys(VALID_COLUMNS_NAMES).forEach((field) => {
      if (!Object.keys(importedFields).includes(field)) availableFields.push(field)
    })

    const fieldsSelection: any[] = []
    if (!availableFields.includes(field))
      fieldsSelection.push({ id: fieldName, value: t(`resources.contract.fields.${fieldName}`) })

    availableFields.forEach((field) => {
      const missingFieldName = USER_FIELDS.includes(field) ? 'user.' + field : field
      fieldsSelection.push({
        id: field,
        value: t(`resources.contract.fields.${missingFieldName}`),
      })
    })
    return fieldsSelection
  }

  function disableNextStep() {
    if (missingFields.length) {
      if (isEqual(missingFields.sort(), ignoredFields.sort())) return false
      return true
    }
    return false
  }

  const Field = (props: FieldProps) => {
    const fieldName = props.name
    const columnName = importedFields[fieldName as keyof typeof importedFields]
    const isMissing = missingFields.includes(fieldName)
    const translationName = USER_FIELDS.includes(props.name) ? 'user.' + props.name : props.name

    if (!Object.keys(importedFields).includes(fieldName) && !missingFields.includes(fieldName))
      return <></>
    if (!columnName) {
      importedFields[fieldName as unknown as number] = unmatchedColumns.pop()
    }

    return (
      <Row sx={fieldSx}>
        <Spacer x={3} />
        <SvgIcon sx={{ color: isMissing ? theme.colors.RED : theme.colors.GREEN }}>
          {isMissing ? <CancelRounded /> : <CheckCircleRounded />}
        </SvgIcon>
        <Spacer x={1} />
        <Typography sx={{ width: '35%' }}>{columnName}</Typography>
        <SimpleForm toolbar={false} sx={{ marginTop: '30px' }}>
          <StyledSelectInput
            label={!isMissing ? '' : t('resources.user.helperTexts.selectInput')}
            source={translationName}
            defaultValue={!isMissing ? translationName : ''}
            variant="outlined"
            choices={getFieldsSelection(missingFields, fieldName)}
            optionText="value"
            optionValue="id"
            emptyText={t('resources.user.emptySelection')}
            onChange={(child) => handleSelectionChange(child, fieldName)}
          />
        </SimpleForm>
        <Spacer x={1} />
        {!REQUIRED_FIELDS.includes(fieldName) ? (
          <FormGroup>
            <FormControlLabel
              sx={{ color: theme.colors.GREY }}
              control={
                <Checkbox
                  checked={ignoredFields.includes(fieldName)}
                  sx={{ color: theme.colors.GREY }}
                  onChange={(event) => handleIgnoreField(event, fieldName)}
                />
              }
              label={t('resources.contract.helperTexts.importTab.ignoreField')}
            />
          </FormGroup>
        ) : (
          <></>
        )}
      </Row>
    )
  }

  function areRequiredFieldsMissing() {
    let containsRequired = false
    missingFields.forEach((field) => {
      if (REQUIRED_FIELDS.includes(field)) containsRequired = true
    })
    return containsRequired
  }

  const MissingFieldHelperText = () => {
    let message: string
    const translatedFields: string[] = []
    const missingRequiredFields = areRequiredFieldsMissing()

    for (const field of missingFields) {
      let fieldName = field
      if (USER_FIELDS.includes(field)) fieldName = 'user.' + field
      if (REQUIRED_FIELDS.includes(field))
        translatedFields.push(t(`resources.contract.fields.${fieldName}`))
    }

    if (disableNextStep()) {
      message = missingRequiredFields
        ? t(
            'resources.contract.helperTexts.importTab.requiredColumnDidntMatch',
            missingFields.length
          )
        : t('resources.contract.helperTexts.importTab.columnDidntMatch', missingFields.length)
    } else message = t('resources.contract.helperTexts.importTab.allColumnsMatch')

    return (
      <>
        <Row sx={{ alignSelf: 'flex-start' }}>
          <Spacer x={3} />
          <SvgIcon
            sx={{
              color: disableNextStep() ? theme.colors.RED : theme.colors.GREEN,
            }}>
            {disableNextStep() ? <CancelRounded /> : <CheckCircleRounded />}
          </SvgIcon>
          <Spacer x={1} />
          <Typography
            sx={{
              color: disableNextStep() ? theme.colors.RED : theme.colors.GREEN,
              fontWeight: disableNextStep() ? 600 : 400,
            }}>
            {message}
          </Typography>
        </Row>
        {missingRequiredFields && (
          <Column sx={{ alignSelf: 'flex-start' }}>
            <Row>
              <Typography
                sx={{
                  ml: translatedFields.length > 4 ? '10px' : '55px',
                  fontSize: '13px',
                }}>
                {t('resources.contract.helperTexts.importTab.columnDidntMatchSubtitleStart')}
              </Typography>
              <Typography>&nbsp;</Typography>
              <Typography
                sx={{
                  alignItems: 'baseline',
                  fontWeight: 600,
                  fontSize: '13px',
                }}>
                {translatedFields.join(', ')}
              </Typography>
            </Row>
            <Typography
              sx={{
                ml: translatedFields.length > 4 ? '10px' : '55px',
                fontSize: '13px',
              }}>
              {t('resources.contract.helperTexts.importTab.columnDidntMatchSubtitleEnd')}
            </Typography>
          </Column>
        )}
      </>
    )
  }

  const ColumnsHeaders = () => {
    return (
      <Row sx={{ width: '100%' }}>
        <Spacer x={3} />
        <Typography
          noWrap
          sx={{
            width: '45%',
            color: theme.colors.GREY,
            fontSize: '16px',
          }}>
          {t('resources.contract.helperTexts.importTab.userColumnsNames')}
        </Typography>
        <Typography
          noWrap
          sx={{
            marginLeft: '-15px',
            width: '45%',
            color: theme.colors.GREY,
            fontSize: '16px',
          }}>
          {t('resources.contract.helperTexts.importTab.nesspayColumnsNames')}
        </Typography>
      </Row>
    )
  }

  return (
    <Column sx={columnSx}>
      <HorizontalStepper activeStep={currentStep} />
      <Spacer y={3} />
      <StyledSquare>
        <Spacer y={1} />
        <StyledTypography>
          {missingFields.length >= 1
            ? t('resources.contract.helperTexts.importTab.missingColumnName')
            : t('resources.contract.helperTexts.importTab.noColumnsMissing')}
        </StyledTypography>
        <MissingFieldHelperText />
        <Spacer y={2} />
        <ColumnsHeaders />
        <Spacer y={1} />
        {FIELDS.map((field) => (
          <Field key={field} name={field} />
        ))}
        <Spacer y={1} />
      </StyledSquare>
      <Spacer y={2} />
      <Row>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setValidationStep(ValidationSteps.FLUSH_DATA)}
          sx={buttonSx}>
          {t('buttons.import.prevStep')}
        </Button>
        <Spacer x={4} />
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextStep}
          sx={buttonSx}
          disabled={disableNextStep()}>
          {t('buttons.import.nextStep')}
        </Button>
      </Row>
    </Column>
  )
}

const fieldSx: SxProps = {
  display: 'flex',
  height: '40px',
  width: '100%',
}
const buttonSx: SxProps = {
  width: '180px',
  height: '45px',
  fontWeight: 400,
  padding: 0,
}
const columnSx: SxProps = {
  display: 'flex',
  width: '100%',
  height: '90%',
  alignItems: 'center',
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  height: '50px',
  width: '400px',
  textAlign: 'center',
  fontSize: '16px',
  color: theme.palette.secondary.main,
}))

const StyledSquare = styled(Column)(({ theme }) => ({
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '650px',
  maxHeight: '520px',
  border: '2px solid',
  borderColor: theme.palette.secondary.main,
  borderRadius: '10px',
}))

const StyledSelectInput = styled(SelectInput)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    width: '220px',
    height: '30px',
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: '10px',
  },
}))
