import { Box, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { useTranslate } from 'react-admin'

import { ImportUsersContext } from 'libs/import/context'

export const ImportErrors = () => {
  const t = useTranslate()
  const { errors } = useContext(ImportUsersContext)
  const errorsList = Object.entries(errors).filter(
    ([column, rowsWithError]) => rowsWithError.length > 0 && column !== 'uniqueness'
  )

  return (
    <Box padding={4}>
      <Typography variant="h2" sx={{ fontWeight: 500 }}>
        {t('resources.contract.helperTexts.importTab.error')}
      </Typography>
      <ul className="error-list">
        {errorsList.map(([column, rowsWithError]) => (
          <li key={column} style={{ margin: 4 }}>
            {t('basics.column')} <strong> {column} </strong>{' '}
            {t('resources.contract.helperTexts.importTab.missingFieldOrFormatError')}:{' '}
            {rowsWithError.join(', ')}
          </li>
        ))}
        {errors.uniqueness.length > 0 ? (
          <li key="uniqueness" style={{ margin: 4 }}>
            {t('resources.contract.helperTexts.importTab.uniquenessError')}:{' '}
            {errors.uniqueness.join(', ')}
          </li>
        ) : null}
      </ul>
    </Box>
  )
}
